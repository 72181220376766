import type Splide from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';
import { BREAKPOINTS } from '@/js/utils/breakpoints';

const splides: Splide[] = [];

const getElements = () => ({
  splideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--partner-slider .splide',
  ),
});

export default defineModule(
  async () => {
    const { splideElements } = getElements();
    if (!splideElements) return;

    const [{ Splide }, { AutoScroll }] = await Promise.all([
      import('@splidejs/splide'),
      import('@splidejs/splide-extension-auto-scroll'),
    ]);

    splideElements.forEach((splideElement) => {
      splides.push(
        new Splide(splideElement, {
          type: 'loop',
          drag: false,
          gap: '1.5rem',
          perPage: 2,
          perMove: 1,
          arrows: false,
          mediaQuery: 'min',
          autoScroll: {
            pauseOnHover: false,
            pauseOnFocus: false,
            speed: 0.5,
          },
          breakpoints: {
            450: {
              perPage: 3,
            },
            [BREAKPOINTS.sm]: {
              perPage: 4,
              gap: '2.5rem',
            },
            [BREAKPOINTS.md]: {
              perPage: 5,
              gap: '3rem',
            },
            [BREAKPOINTS.lg]: {
              perPage: 6,
              gap: '4rem',
            },
            [BREAKPOINTS.xl]: {
              gap: '5rem',
            },
          },
        }).mount({ AutoScroll }),
      );
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
