import { v4 as uuid } from 'uuid';
import { AJAX_API } from '../utils/api';
import { defineModule } from '../utils/helpers';

const targetToIdMap = new Map<string, string>();
const currentPageMap = new Map<string, number>();

const toggleDisableLoadMoreButtons = (
  id: string,
  forceDisable?: boolean,
  completed = false,
) => {
  document
    .querySelectorAll<HTMLElement>(
      `[data-load-more-id="${id}"][data-load-more-target]`,
    )
    .forEach((element) => {
      const shouldDisable = forceDisable ?? !element.hasAttribute('disabled');
      element.toggleAttribute('disabled', shouldDisable);

      if (completed) {
        element.classList.add('load-more--completed');
      }
    });
};

const loadMore = async (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const target = e.currentTarget.getAttribute('data-load-more-target');
  if (!target) return;

  const postType =
    e.currentTarget.getAttribute('data-load-more-post-type') ?? 'post';
  const perPage = parseInt(
    e.currentTarget.getAttribute('data-load-more-per-page') ?? '1',
  );
  const offset = parseInt(
    e.currentTarget.getAttribute('data-load-more-offset') ?? '0',
  );
  const orderby =
    e.currentTarget.getAttribute('data-load-more-orderby') ?? 'date';

  const order = e.currentTarget.getAttribute('data-load-more-order') ?? 'DESC';

  const targetElement = document.querySelector<HTMLElement>(target);
  if (!targetElement) return;

  const id = e.currentTarget.getAttribute('data-load-more-id');
  if (!id) return;

  let currentPage = currentPageMap.get(id) ?? 0;
  currentPageMap.set(id, (currentPage += 1));

  try {
    toggleDisableLoadMoreButtons(id, true);

    const { max, count, html } = await AJAX_API.formData({
      action: 'load_more',
      post_type: postType,
      posts_per_page: perPage,
      offset,
      page: currentPage,
      orderby,
      order,
    })
      .post()
      .json<{ max: number; count: number; html: string }>()
      .then((data) => {
        toggleDisableLoadMoreButtons(id, false);
        return data;
      });

    targetElement.insertAdjacentHTML('beforeend', html);

    if (!max || currentPage + 1 === max) {
      toggleDisableLoadMoreButtons(id, true, true);
    }

    document.dispatchEvent(
      new CustomEvent('loadMore', {
        detail: {
          postType,
          elements: [...targetElement.children].slice(
            targetElement.children.length - count,
          ) as HTMLElement[],
        },
      }),
    );
  } catch (error) {
    currentPageMap.set(id, currentPage - 1);
  }
};

export default defineModule(
  () => {
    const loadMoreElements =
      document.querySelectorAll<HTMLElement>('[data-load-more]');

    loadMoreElements.forEach((loadMoreElement) => {
      loadMoreElement.addEventListener('click', loadMore);

      const target = loadMoreElement.getAttribute('data-load-more-target');
      if (!target || loadMoreElement.hasAttribute('data-load-more-id')) return;

      const id = targetToIdMap.get(target) || uuid();
      loadMoreElement.setAttribute('data-load-more-id', id);
      targetToIdMap.set(target, id);
    });
  },
  () => {
    const loadMoreElements =
      document.querySelectorAll<HTMLElement>('[data-load-more]');

    loadMoreElements.forEach((loadMoreElement) => {
      loadMoreElement.removeEventListener('click', loadMore);
    });

    currentPageMap.clear();
  },
);
