import { defineModule, isTouch } from '@/js/utils/helpers';

const getElements = () => ({
  mapBlockElements: document.querySelectorAll<HTMLElement>(
    '.content-block--map',
  ),
});

const handleTabMouseEvent = (e: MouseEvent, action: 'enter' | 'leave') => {
  if (
    isTouch() ||
    !(e.currentTarget instanceof HTMLElement) ||
    e.currentTarget.getAttribute('aria-selected') === 'true'
  )
    return;

  const { municipality } = e.currentTarget.dataset;
  if (!municipality) return;

  const municipalityElement = e.currentTarget
    .closest('.content-block--map')
    ?.querySelector<SVGPathElement>(
      `.map__map path[data-municipality="${municipality}"]`,
    );
  if (!municipalityElement) return;

  if (action === 'enter') {
    municipalityElement.classList.add('fill-secondary');
  } else if (action === 'leave') {
    municipalityElement.classList.remove('fill-secondary');
  }
};

const onTabMouseEnter = (e: MouseEvent) => handleTabMouseEvent(e, 'enter');
const onTabMouseLeave = (e: MouseEvent) => handleTabMouseEvent(e, 'leave');

const onTabClick = (e: Event) => {
  if (!(e.currentTarget instanceof HTMLElement)) return;

  const { municipality } = e.currentTarget.dataset;
  if (!municipality) return;

  const tabpanelId = e.currentTarget.getAttribute('aria-controls');
  if (!tabpanelId) return;

  const tabpanelElement = document.getElementById(tabpanelId);
  if (!tabpanelElement) return;

  const contentBlockElement = tabpanelElement.closest<HTMLElement>(
    '.content-block--map',
  );
  if (!contentBlockElement) return;

  contentBlockElement
    .querySelectorAll<HTMLElement>('[role="tabpanel"]')
    .forEach((el) => {
      const isSelected = el === tabpanelElement;
      el.setAttribute('aria-expanded', `${isSelected}`);
      el.toggleAttribute('hidden', !isSelected);
    });

  contentBlockElement
    .querySelectorAll<HTMLElement>('[role="tab"]')
    .forEach((tab) => {
      tab.setAttribute('aria-selected', `${tab === e.currentTarget}`);
    });

  contentBlockElement
    .querySelectorAll<SVGPathElement>('.map__map path[data-municipality]')
    .forEach((municipalityPathElement) => {
      const isActive =
        municipalityPathElement.dataset.municipality === municipality;

      if (isActive) {
        /**
         * Moving the element to the bottom of parent,
         * fixes drop shadow z-index
         */
        municipalityPathElement.parentElement?.appendChild(
          municipalityPathElement,
        );
      }

      [
        'fill-secondary',
        '!stroke-[4]',
        'drop-shadow-[0_0_10px_theme(colors.black/25%)]',
      ].forEach((className) => {
        municipalityPathElement.classList.toggle(className, isActive);
      });
    });
};

const onMunicipalityPathClick = (e: Event) => {
  if (!(e.currentTarget instanceof SVGElement)) return;

  const { municipality } = e.currentTarget.dataset;
  if (!municipality) return;

  e.currentTarget
    .closest<HTMLElement>('.content-block--map')
    ?.querySelector<HTMLElement>(
      `.map__tabs [role="tab"][data-municipality="${municipality}"]`,
    )
    ?.click();
};

export default defineModule(
  () => {
    const { mapBlockElements } = getElements();

    mapBlockElements.forEach((mapBlockElement) => {
      const tabElements = mapBlockElement.querySelectorAll<HTMLButtonElement>(
        '.map__tabs > button[role="tab"]',
      );
      tabElements.forEach((tabElement, index) => {
        tabElement.addEventListener('click', onTabClick);
        tabElement.addEventListener('mouseenter', onTabMouseEnter);
        tabElement.addEventListener('mouseleave', onTabMouseLeave);

        if (index === 0) {
          tabElement.click();
        }
      });

      const municipalityPathElements =
        mapBlockElement.querySelectorAll<HTMLButtonElement>(
          '.map__map path[data-municipality]',
        );
      municipalityPathElements.forEach((municipalityPathElement) => {
        municipalityPathElement.addEventListener(
          'click',
          onMunicipalityPathClick,
        );
      });
    });
  },
  () => {
    const { mapBlockElements } = getElements();

    mapBlockElements.forEach((mapBlockElement) => {
      const tabElements = mapBlockElement.querySelectorAll<HTMLButtonElement>(
        '.map__tabs > button[role="tab"]',
      );
      tabElements.forEach((tabElement) => {
        tabElement.removeEventListener('click', onTabClick);
        tabElement.removeEventListener('mouseenter', onTabMouseEnter);
        tabElement.removeEventListener('mouseleave', onTabMouseLeave);
      });

      const municipalityPathElements =
        mapBlockElement.querySelectorAll<HTMLButtonElement>(
          '.map__map path[data-municipality]',
        );
      municipalityPathElements.forEach((municipalityPathElement) => {
        municipalityPathElement.removeEventListener(
          'click',
          onMunicipalityPathClick,
        );
      });
    });
  },
);
